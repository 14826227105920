export const newCourse = {
  v1: "new_course",
  v2: "new_course_v2",
  v3: "new_course_v3",
  v4: "new_course_v4",
};

export const lessonContent = {
  v1: "lesson_content",
  v2: "lesson_content_v2",
};

export const getCourse = {
  v1: "get_course",
};
